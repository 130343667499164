.box-shadow-1 {
    box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.box-shadow-2 {
    box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.24), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.box-shadow-3 {
        position: relative;
        background: $gray-100;
        border-radius: 10px;
        padding: 1rem;

            top: 14px;
            right: -5px;
            border-style: solid;
            border-color: transparent transparent $gray-100 transparent;


}
