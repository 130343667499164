.app-footer {
    margin-top: 2rem;
    background: #eee;
    padding: 1.25rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .footer-bottom {
        width: 100%;
        .logo {
            width: 3rem;
            height: auto;
            margin: 4px;
        }
    }
}