._dot {
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
}
._inline-dot {
  display: inline-block;
}

._round-button {
  border-radius: 50% !important;
}
.progress--height-2 {
  height: 5px;
}
.text-middle {
  vertical-align: middle !important;
}
.btn-hover {
  &:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }
}
.btn-ACK {
  color: #fff;
  background-color: #3f6ad8;
  border-color: #3f6ad8
}
.btn-secondary {
   color: #fff;
   background-color: #236c8e;
   border-color: #236c8e
 }
.btn-DONE {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50
}
.btn-IN_PROCESS {
  color: #fff;
  background-color: #3f6ad8;
  border-color: #3f6ad8
}
.btn-REOPEN {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107
}
.btn-CANCEL {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336
}

