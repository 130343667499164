html [type='button'],
.btn[type='button'] {
  -webkit-appearance: none !important;
}

.btn {
  &.rounded,
  &.btn-rounded {
    border-radius: 40px !important;
  }
  &.btn-xl {
    padding: 0.75rem 2rem;
    font-size: 1.18rem;
  }
  // padding: .5rem 1.25rem;
  &:focus {
    box-shadow: none;
  }
}

.btn-secondary,
.btn-raised-secondary {
  color: $foreground !important;
  background-color: $background !important;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .ul-btn__icon {
    margin-right: 5px;
    margin-bottom: -1px;
  }
}
// .btn-icon {
//   [class^='i-'],
//   .icon {
//     vertical-align: middle;
//     margin: 0 2px;
//     -webkit-font-smoothing: subpixel-antialiased;
//   }
//   &.rounded-circle {
//     width: 44px;
//     height: 44px;
//     padding: 0;
//   }
// }

.btn-icon-text {
  [class^='i-'],
  .icon {
    vertical-align: middle;
    margin: 0 2px;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

.btn-outline-email {
  background: rgba($gray-200, 0.6);
}

.btn-spinner {
  width: 1em;
  height: 1em;
  background: transparent;
  border-radius: 50%;
  margin: 0 16px 0 0;
  border: 2px solid transparent;
}

.btn-checkbox {
  .checkbox {
    display: inline;
  }
}

.btn.btn-outline-light.btn-svg {
  border-color: $gray-700;
  &.active,
  &:hover {
    background: $primary;
    border-color: $primary;
    svg {
      fill: #ffffff;
    }
  }
  &:focus {
    box-shadow: none !important;
  }
}

.btn-raised {
  color: #fff;
}

@each $name, $value in $theme-colors {
  .btn-#{$name},
  .btn-outline-#{$name} {
    border-color: $value;
    .btn-spinner {
      animation: btn-glow-#{$name} 1s ease infinite;
    }
    &:hover {
      background: $value;
      box-shadow: 0 8px 25px -8px $value;
      border-color: $value;
    }
    &:focus {
      box-shadow: none;
      box-shadow: 0 8px 25px -8px $value;
    }
  }
  .btn-raised.btn-raised-#{$name} {
    background: $value;
    box-shadow: 0 4px 6px rgba($value, 0.11), 0 1px 3px rgba($value, 0.08);
  }
  @keyframes btn-glow-#{$name} {
    0% {
      box-shadow: 0 0 0 0.4em darken($value, 10%), 0 0 0 0.1em darken($value, 10%);
      transform: rotate(360deg);
    }
    50% {
      border-top-color: #ffffff;
    }
    100% {
      box-shadow: 0 0 0 0.4em darken($value, 10%), 0 0 0 3.6em transparent;
    }
  }
}

// extra button
// .ul-btn-raised--v2{
//   transition: 0.3s ease-in;
// }
// .ul-btn-raised--v2:hover{
//   transform: translateY(-2px);
//   -webkit-box-shadow: -1px 9px 11px -4px rgba(0,0,0,0.1);
//   -moz-box-shadow: -1px 9px 11px -4px rgba(0,0,0,0.1);
//   box-shadow: -1px 9px 11px -4px rgba(0,0,0,0.1);
// }
// .card-title--margin{
//   margin-bottom: 12px;
// }
// .ul-btn__icon span:last-child{
//   margin-left: 5px;
// }
//social button
.btn-facebook {
  color: $white;
  background-color: $facebook;
  border-color: $facebook;
}

.btn-facebook:hover {
  background-color: $facebook;
  border-color: $facebook;
}

.btn-google {
  color: $white;
  background-color: $google;
  border-color: $google;
}

.btn-twitter {
  color: $white;
  background-color: $twitter;
  border-color: $twitter;
}

.btn-instagram {
  color: $white;
  background-color: $instagram;
  border-color: $instagram;
}

.btn-linkedin {
  color: $white;
  background-color: $linkedin;
  border-color: $linkedin;
}

.btn-dribble {
  color: $white;
  background-color: $dribble;
  border-color: $dribble;
}

.btn-youtube {
  color: $white;
  background-color: $youtube;
  border-color: $youtube;
}

// Social outline
.btn-outline-facebook {
  color: $facebook !important;
  border-color: $facebook;
  background: rgba($gray-200, 0.6);
  &:hover {
    background: darken($facebook, 5%);
    border-color: darken($facebook, 5%);
    color: $white !important;
  }
}

.btn-outline-google {
  color: $google !important;
  border-color: $google;
  background: rgba($gray-200, 0.6);
  &:hover {
    background: darken($google, 5%);
    border-color: darken($google, 5%);
    color: $white !important;
  }
}

.btn-outline-twitter {
  color: $twitter !important;
  border-color: $twitter;
  background: rgba($gray-200, 0.6);
  &:hover {
    background: darken($twitter, 5%);
    border-color: darken($twitter, 5%);
  }
}

// end social
// .ul-bottom__line{
//   padding-bottom: 18px;
//   border-bottom: 1px solid $gray-400;
// }
// .ul-bottom__line:last-child{
//   border-bottom: none;
// }
// .round{
//   border-radius: 50%;
// }
// Ripple Effect
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.btn2 {
  font-size: 0.8rem;
  font-weight: 500;
}

.btn2.btn-pill2.btn-wide2,
.btn.btn-pill2 {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.btn-primary2.btn-shadow2 {
  box-shadow: 0 0.125rem 0.625rem #994c12, 0 0.0625rem 0.125rem #eb850f;
  //box-shadow: #129A3D
}

.btn-primary2.btn-shadow2:hover {
  box-shadow: 0 0.125rem 0.625rem #994c12, 0 0.0625rem 0.125rem #eb850f;
  //box-shadow: 0 0.125rem 0.625rem rgba(63, 106, 216, 0.5), 0 0.0625rem 0.125rem rgba(63, 106, 216, 0.6)
  //box-shadow: #129A3D
}
.btn2.btn-wide2 {
  padding: 0.375rem 1.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.btn-primary2 {
  color: #fff;
  background-color: #eb850f;
  border-color: #eb850f;
}
.btn-hover-shine2 {
  position: relative;
}

.btn-hover-shine2:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.btn-hover-shine2:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.4s ease-in-out;
}

.btn-lg2,
.btn-group-lg2 > .btn2 {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn.btn-disabled,
.btn:disabled,
.btn[disabled] {
  opacity: 0.4 !important;
}

.btn-xs {
  min-width: 24px;
  min-height: 24px;
  padding: 0.3rem 0.1rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
  line-height: 1;
}
