.ui-head-tabs div.ant-tabs-nav {
  border: 0;
  margin: 0;
}

.ui-head-tabs div.ant-tabs-nav::before {
  border-bottom: none;
}
.uiactions {
  display: inline-flex;
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}
