.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  color: #fff;
  background-color: $table-dark-bg;
  font-weight: 600;
}

.line-clamp-1 {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  line-clamp: 1;
  -webkit-box-orient: vertical;
}

label.ant-segmented-item {
  margin-bottom: 0;
}

button.ant-btn-link {
  padding: 0;
  height: auto;
}

.custom-tabs {
  div.ant-tabs-nav {
    border: 0;
    margin: 0;
  }
  div.ant-tabs-nav::before {
    border-bottom: none;
  }
}

.card-actions {
  position: absolute;
  right: 18px;
  top: 12px;
}

.ant-btn > span {
  display: inline-flex;
}

div.ant-upload.ant-upload-select.ant-upload-select-text {
  display: block;
}

.ant-btn {
  &.disabled,
  &:disabled {
    color: rgba(0, 0, 0, 0.4);
  }
}
